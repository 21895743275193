<script>
import {
  BButton,
  BFormInput,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
} from 'bootstrap-vue'

export default {
  name: 'CorrectionModal',
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
  },
  props: {
    manualCorrectionData: {
      type: Object,
      default: () => {},
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    sensors: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      newValue: '',
    }
  },
  computed: {
    isCorrectionModalVisible: {
      get() {
        return this.isVisible
      },
      set() {
        return this.isVisible
      },
    },
    formattedValue: {
      get() {
        return this.dataToTime(this.newValue)
      },
      set(value) {
        this.newValue = this.parseFormattedTime(value)
      },
    },
  },
  methods: {
    dataToTime(value) {
      return this.$moment.unix(value / 1000).format('HH:mm:ss')
    },
    deleteManualCorrection(index) {
      this.manualCorrection.splice(index, 1)
      localStorage.setItem(
        'manual_correction',
        JSON.stringify(this.manualCorrection),
      )
    },
    parseFormattedTime(time) {
      const [hours, minutes, seconds] = time.split(':')
      const date = this.$moment().set({
        hours,
        minutes,
        seconds,
        millisecond: 0,
      })
      return date.valueOf()
    },
    saveChanges() {
      this.$emit(
        'onCorrectionChange',
        this.sensors.correctChip,
        this.sensors.correctKey,
        this.newValue,
      )
    },
    handlerCloseModal() {
      this.$emit('close')
    },
  },
}
</script>

<template>
  <div>
    <b-modal
      v-model="isCorrectionModalVisible"
      ok-only
      ok-title="Сохранить"
      modal-class="modal-primary"
      centered
      title="Корректировка Значения"
      @ok="saveChanges"
      @hidden="handlerCloseModal"
    >
      <b-form-input
        v-model="formattedValue"
        v-mask="'##:##:##'"
        class="links__input mb-1"
        placeholder="Нужное значение"
      />
      <b-form-group
        v-if="manualCorrectionData.dispersion.length"
        v-slot="{ ariaDescribedby }"
        label="Возможные значения"
        class="row px-1"
      >
        <b-form-radio-group
          v-model="newValue"
          :aria-describedby="ariaDescribedby"
          name="radio-inline"
          class="d-flex"
        >
          <b-form-radio
            v-for="item in manualCorrectionData.dispersion"
            :key="item"
            :value="item"
            class="mx-1"
          >
            {{ dataToTime(item) }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <b-form-group
        v-if="manualCorrectionData.error.length"
        v-slot="{ ariaDescribedby }"
        label="Ошибочные значения"
        class="row px-1"
      >
        <b-form-radio-group
          v-model="newValue"
          :aria-describedby="ariaDescribedby"
          name="radio-inline"
          class="d-flex"
        >
          <b-form-radio
            v-for="item in manualCorrectionData.error"
            :key="item"
            :value="item"
            class="mx-1"
          >
            {{ dataToTime(item) }}
          </b-form-radio>
        </b-form-radio-group>
      </b-form-group>
      <p class="text-center">
        Данные в таблице будут обновлены при следующей синхронизации. <br />
        Пожалуйста, проявите терпение.
      </p>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}
</style>
