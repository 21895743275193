<template>
  <div>
    <div class="mt-3">
      Группа {{ groupIndex + 1 }}
      <b-button
        v-if="!isRestricted"
        title="Удалить"
        variant="flat-danger"
        class="btn-icon inline-block"
        size="sm"
        @click="deleteGroup"
      >
        <feather-icon icon="MinusIcon" />
      </b-button>
    </div>
    <template v-if="groupState">
      <b-form-input
        :key="'group.name_' + groupIndex"
        v-model="groupState.name"
        placeholder="Название"
        class="inline-block"
      />

      <div
        :key="'group.age_' + groupIndex"
        style="margin-top: 20px"
      >
        Возраст
      </div>
      <div
        :key="'group.from_to_' + groupIndex"
        class="age"
      >
        <b-form-input
          v-model.number="groupState.age_from"
          style="margin-top: 10px!important"
          placeholder="От"
          class="inline-block"
        />
        <b-form-input
          v-model.number="groupState.age_to"
          style="margin-top: 10px!important"
          placeholder="До"
          class="inline-block"
        />
      </div>
      <div class="mt-2">
        <div>Считать возраст участников на дату:</div>
        <flat-pickr
          v-model="groupState.age_reference_date"
          class="form-control"
          :config="config"
        />
      </div>
      <div
        :key="'group.groupType' + groupIndex"
        style="margin-top: 20px"
      >
        Формат группы
      </div>
      <v-select
        v-model="groupState.format"
        label="name"
        :options="sexArrow"
        :reduce="option => option.value"
      />
      <div
        v-if="groupState.format === 'Combined'"
        :key="'group.stages_' + groupIndex"
      >
        <stage
          v-for="(stage, index) in stages"
          :key="'group_' + groupIndex + 'stage' + index"
          :combination_dict="group.combination_dict"
          :stage="stage"
          :stage-index="index"
          :group-type="groupState.format === 'Combined'"
        />
      </div>
      <!-- <div
        v-else
        class="inline-block mt-2"
      >
        <div class="inline-block mr-1">
          <b-form-checkbox
            v-model="groupState.sex"
            value="male"
          >М
          </b-form-checkbox>
        </div>
        <div class="inline-block">
          <b-form-checkbox
            v-model="groupState.sex"
            value="female"
          >Ж
          </b-form-checkbox>
        </div>
      </div> -->
    </template>
  </div>
</template>

<script>
import {
  BFormInput, BFormCheckbox, BButton, BFormCheckboxGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import Stage from './Stage.vue'

export default {
  name: 'Group',
  components: {
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormCheckboxGroup,
    Stage,
    vSelect,
    flatPickr,
  },
  props: ['group', 'groupIndex', 'isRestricted', 'distanceFormat', 'stages'],
  data() {
    return {
      groupState: this.group,
      sexArrow: [
        { name: 'Мужской', value: 'Male' },
        { name: 'Женский', value: 'Female' },
        { name: 'Любой', value: 'Any' },
      ],
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
    }
  },
  watch: {
    group: {
      handler() {
        this.groupState = this.group
        console.log(this.groupState)
      },
      immediate: true,
      deep: true,
    },
    groupState: {
      handler(newValue, prev) {
        this.$emit('updateGroup', this.groupState, this.groupIndex)
      },
      deep: true,
    },
    distanceFormat: {
      handler(newValue, prev) {
        if (this.distanceFormat === 'Эстафета') {
          this.sexArrow.push({ name: 'Смешанный', value: 'Combined' })
        }
      },
      immediate: true,
    },
  },
  methods: {
    deleteGroup() {
      this.$emit('delete-group', this.groupIndex)
    },
  },
}
</script>
