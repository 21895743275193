<template>
  <div v-if="currentMember">
    <div class="input">
      <span>
        ID
      </span>
      <b-form-input
        v-model="currentMember.id"
        readonly
        placeholder="ID"
      />
    </div>
    <div class="input">
      <span>
        Имя
      </span>
      <b-form-input
        v-model="currentMember.name"
        placeholder="Имя"
      />
    </div><div class="input">
      <span>
        Фамилия
      </span>
      <b-form-input
        v-model="currentMember.surname"
        placeholder="Фамилия"
      />
    </div>
    <div class="input">
      <span>
        Пол
      </span>
      <b-form-input
        v-model="currentMember.gender"
        placeholder="Пол"
      />
    </div><div class="input">
      <span>
        Email
      </span>
      <b-form-input
        v-model="currentMember.email"
        placeholder="Email"
      />
    </div>
    <div class="input">
      <span>
        Дистанция
      </span>
      <b-form-input
        v-model="currentMember.distance_relation.name"
        readonly
        placeholder="Дистанция"
      />
    </div>
    <div class="input">
      <span>
        Телефон
      </span>
      <b-form-input
        v-model="currentMember.phone"
        placeholder="Телефон"
      />
    </div>
    <div class="input">
      <span>
        Команда
      </span>
      <b-form-input
        v-model="currentMember.team"
        placeholder="Команда"
      />
    </div>
    <div class="input">
      <span>
        Дата рождения
      </span>
      <flat-pickr
        v-model="currentMember.birthday"
        class="form-control"
        :config="config"
      />
    </div>
    <div class="input">
      <span>
        Статус
      </span>
      <b-form-input
        v-model="currentMember.payment"
        placeholder="Статус"
      />
    </div>
    <div class="input">
      <span>
        Сбербанк ID
      </span>
      <b-form-input
        readonly
        v-model="currentMember.sberbank_id"
        placeholder="Сбербанк ID"
      />
    </div>
    <div class="input">
      <span>
        Группа
      </span>
      <b-form-input
        v-model="currentMember.age_group.name"
        readonly
        placeholder="Группа"
      />
    </div>
    <div class="input">
      <span>
        Цена
      </span>
      <b-form-input
        v-model="currentMember.price"
        placeholder="Цена"
      />
    </div>
    <b-button
      variant="primary"
      class="mr-1"
      @click="saveMember"
    >
      Сохранить
    </b-button>
  </div>
</template>

<script>
import {
  BFormInput, BButton, VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import crud_module from '@/libs/crud_module'

const member_module = crud_module({
  entity_name: 'member-start',
})

export default {
  name: 'EditMember',
  components: {
    BFormInput,
    flatPickr,
    BButton,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: ['member', 'modalRef'],
  data() {
    return {
      currentMember: null,
      config: {
        allowInput: true,
        altFormat: 'd.m.Y',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
    }
  },
  async created() {
    this.currentMember = { ...this.member, price: Number(this.member.price) / 100 }
  },

  methods: {
    async saveMember() {
      const result = await member_module.updateItem(this.currentMember.id, {
        name: this.currentMember.name,
        surname: this.currentMember.surname,
        sex: this.currentMember.gender,
        email: this.currentMember.email,
        number: this.currentMember.phone,
        team: this.currentMember.team,
        dob: this.currentMember.birthday,
        payment: this.currentMember.payment,
        price: `${this.currentMember.price * 100}`,
      }).then(r => r.data)
      if (result.success) {
        this.$emit('save')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Старт сохранен',
            variant: 'success',
          },
        })
        this.modalRef.hide()
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Ошибка сохранения',
            variant: 'danger',
          },
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.input{
  margin-bottom: 15px;
  span{
    padding-bottom: 8px;
    display: block;
  }
}
</style>
