<template>
  <div>
    <b-modal
      :id="'bv-modal-combo'"
      class="z-index"
      title="Создать комбо из дистанций"
      hide-footer
      @hidden="resetModal"
    >
      <b-form-input
        v-model="comboName"
        placeholder="Название"
        label="Название"
        vs-placeholder="Название"
        class="w-full"
      />

      <b-form-checkbox-group
        v-for="(distance, index) in distances"
        :key="index"
        v-model="selectedDistances"
      >
        <b-form-checkbox
          :key="index"
          class="mt-1 mr-1"
          :value="distance"
        >
          {{ distance.name }}
        </b-form-checkbox>
      </b-form-checkbox-group>

      <div class="mt-2">
        <div class="mb-1">
          Скидка за комбо
        </div>
        <b-form-input
          v-model.number="combo_discount_value"
          placeholder="Скидка"
          vs-placeholder="Скидка"
          class="inline-block mb-2"
        />
        <div class="switch">
          <div style="margin-bottom: 10px">
            Скидка в %
          </div>
          <b-form-checkbox
            v-model="combo_discount_is_percent"
            class="custom-control-primary"
            name="check-reg"
            style="margin-bottom: 10px"
            switch
          />
        </div>
      </div>
      <span
        id="save-button"
        class="d-inline-block button-container"
      >
        <b-button
          id="tooltip-target-1"
          type="submit"
          variant="primary"
          class="mr-1 combo-button"
          :disabled="selectedDistances.length < 2"
          @click="addComboDistances"
        >
          Создать
        </b-button>
      </span>
      <b-tooltip
        v-if="selectedDistances.length < 2"
        target="save-button"
      >
        Комбо должно содержать минимум 2 дистанции
      </b-tooltip>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormCheckbox, BButton, BModal, BFormRadio, BFormCheckboxGroup,
  BTooltip,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import { v4 as uuidv4 } from 'uuid'
import FileUploader from '../../../global-components/FileUploader'

export default {
  name: 'DistanceModalCombo',
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    BFormRadio,
    BFormCheckboxGroup,
    BTooltip,
    flatPickr,
    FileUploader,
  },
  props: ['select_kinds_sport'],
  data() {
    return {
      selectedDistances: [],
      sale: null,
      comboName: null,
      prices: [],
      combo_discount_is_percent: false,
      combo_discount_value: null,
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
    }
  },
  computed: {
    distances() {
      return this.select_kinds_sport.filter(item => !item.related_distances)
    },
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    addComboDistances() {
      const uuid = uuidv4()
      this.select_kinds_sport.push({
        name: this.comboName,
        type: 'combo',
        combo_discount_is_percent: this.combo_discount_is_percent,
        combo_discount_value: this.combo_discount_value,
        related_distances: this.selectedDistances.map(e => e.uuid),
        uuid,
      })
    },
    addPrices() {
      this.prices.push({ c_to: '', c_from: '', value: '' })
    },
    resetModal() {
      this.combo_discount_is_percent = null,
      this.combo_discount_value = null,
      this.selectedDistances = []
    },
  },
}
</script>

<style lang="scss" scoped>
.button-container {
  float: right;
}
</style>
