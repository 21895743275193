<template>
  <div>
    <b-table
      :items="results"
      :fields="fields"
      striped
      responsive
    >
      <template #cell(createdAt)="data">
        {{$moment(data.item.createdAt).format('DD.MM.YYYY') }}
      </template>
    </b-table>
    <b-button
      variant="primary"
      class="mr-1"
      :disabled="downloadDisabled"
      @click="downloadExcel"
    >
      <b-spinner
        v-if="downloadDisabled"
        small
      />
      {{ downloadDisabled ? 'Скачивание...' : 'Скачать Excel' }}
    </b-button>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import xlsx from 'json-as-xlsx'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'StartMembersResults',
  components: {
    BTable,
    BButton,
    BSpinner,
  },
  props: ['start_id', 'columns'],
  data() {
    return {
      loading: false,
      results: [],
      downloadDisabled: false,
    }
  },
  computed: {
    fields() {
      const circles = []
      this.results.forEach(e => {
        if (typeof e.circles === 'string') e.circles = JSON.parse(e.circles)
      })
      const max_columns_item = this.results.sort((a, b) => b.circles.length - a.circles.length )[0]
      if (max_columns_item) {
        max_columns_item.circles.forEach((e, i) => {
          const label = this.columns && this.columns[i] ? this.columns[i].value : `Круг ${(i + 1)}`
          circles.push({
            key: `circle${(i + 1)}`,
            label: label,
          })
        })
      }
      return [
        {
          key: 'name',
          label: 'Имя',
        },
        {
          key: 'place',
          label: 'Место',
        },
        {
          key: 'team',
          label: 'Команда',
        },
        {
          key: 'birthday',
          label: 'Дата Рождения',
        },
        {
          key: 'body_number',
          label: 'Номер',
        },
        {
          key: 'group',
          label: 'Группа',
        },
        ...circles,
        {
          key: 'result',
          label: 'Результат',
        },
        {
          key: 'shift',
          label: 'Отставание от лидера',
        },
        {
          key: 'distance',
          label: 'Дистанция',
        },
      ]
    },
  },
  created() {
    this.refreshResults()
  },
  methods: {
    downloadExcel() {
      if (!this.results || this.results.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Нет участников',
            icon: 'BellIcon',
            text: 'Нет зарегистрированных участников на старт для скачивания файла',
            variant: 'warning',
          },
        })
        return false
      }
      this.downloadDisabled = true

      const data = [
        {
          sheet: 'Участники',
          columns: this.fields.map(e => {
            return {
              label: e.key,
              value: e.key,
            }
          }),
          content: this.results,
        },
      ]

      const settings = {
        fileName: 'Результаты',
        extraLength: 3,
        writeOptions: {},
      }

      xlsx(data, settings)

      this.downloadDisabled = false
    },
    async refreshResults() {
      this.loading = true
      this.results = await this.$http.get(`/member-result?start_id=${this.start_id}`).then(result => result.data.rows.map(item => {
        const circles = {}
        JSON.parse(item.circles).forEach((circle, i) => {
          circles[`circle${(i + 1)}`] = circle
        })
        return {
          ...item,
          ...circles,
        }
      }))
      this.loading = false
    },
  },
}
</script>

<style scoped>
</style>