<script>
import {
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BRow,
  BTableSimple,
  BTd,
  BTh,
  BThead,
  BTr,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import draggable from 'vuedraggable'

export default {
  name: 'CheckpointBuilder',
  components: {
    draggable,
    BButton,
    BFormGroup,
    BFormInput,
    BTableSimple,
    BTd,
    BTh,
    BTr,
    BThead,
    BDropdown,
    BDropdownItem,
    BCol,
    BRow,
    BFormInvalidFeedback,
  },
  data() {
    return {
      checkpoints: [],
      checkpointsSettings: [],
      newPoint: null,
      drag: false,
    }
  },
  methods: {
    addCheckpoint() {
      if (!this.newPoint) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            icon: 'BellIcon',
            text: 'Название точки не может быть пустым',
            variant: 'warning',
          },
        })

        return
      }

      const checkpoints = this.newPoint
        .trim()
        .split(';')
        .map(cp => ({
          point: cp.trim(),
        }))
        .filter(Boolean)

      if (!Array.isArray(checkpoints) || checkpoints.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            icon: 'BellIcon',
            text: 'Название точки не может быть пустым',
            variant: 'warning',
          },
        })

        return
      }

      this.checkpoints.push(...checkpoints)
      this.newPoint = null

      this.handleAdd()
      this.emitData()
    },
    deleteCheckpoint(index) {
      const removedElement = this.checkpoints.splice(index, 1)
      this.handleDelete(removedElement[0])
      this.emitData()
    },
    handleAdd() {
      const currentUniqueCheckPoints = this.checkpointsSettings.map(
        checkpoint => checkpoint.point,
      )

      const uniqueCheckpoints = [
        ...new Set(this.checkpoints.map(checkpoint => checkpoint.point)),
      ]

      const newCheckpoints = uniqueCheckpoints.filter(
        x => !currentUniqueCheckPoints.includes(x),
      )

      newCheckpoints.forEach(item => {
        this.checkpointsSettings.push({
          point: item,
          time: null,
        })
      })
    },
    handleDelete(removedElement) {
      const isAnySameCheckpoint = this.checkpoints.some(
        checkpoint => checkpoint.point === removedElement.point,
      )

      if (!isAnySameCheckpoint) {
        const index = this.checkpointsSettings.findIndex(
          setting => setting.point === removedElement.point,
        )

        if (index !== -1) {
          this.checkpointsSettings.splice(index, 1)
        }
      }
    },
    validateTimeInput(time) {
      return time > 0
    },
    onDragStart() {
      this.drag = true
    },
    onDragEnd() {
      this.drag = false
      this.emitData()
    },
    emitData() {
      this.$emit('onSettingsUpdated', {
        checkpoints: this.checkpoints,
        settings: this.checkpointsSettings,
        totalCheckpointsCount: this.checkpoints.length,
        uniqueCheckpointsCount: this.checkpointsSettings.length,
      })
    },
  },
}
</script>

<template>
  <div>
    <h1>Контрольные точки</h1>

    <b-form-group
      label="Добавить контрольную точку"
      class="mb-2"
    >
      <div class="d-flex">
        <b-form-input
          v-model="newPoint"
          class="links__input w-25"
          placeholder="Новая контрольная точка"
        />

        <b-button
          type="submit"
          variant="primary"
          class="ml-2"
          @click="addCheckpoint"
        >
          +
        </b-button>
      </div>
    </b-form-group>

    <div>
      <b-row>
        <b-col
          v-if="checkpoints.length > 0"
          cols="8"
        >
          <p>Список</p>
          <p>Общее количество точек: {{ checkpoints.length }}</p>
          <b-table-simple>
            <b-thead>
              <b-th class="w-25"> Порядковый номер </b-th>

              <b-th> Точка </b-th>

              <b-th> Действие </b-th>
            </b-thead>

            <draggable
              v-model="checkpoints"
              group="checkpoints"
              tag="tbody"
              @start="onDragStart"
              @end="onDragEnd"
            >
              <b-tr
                v-for="(item, index) in checkpoints"
                :key="index"
                class="item-row"
              >
                <b-td>{{ index + 1 }}</b-td>
                <b-td>{{ item.point }}</b-td>
                <b-td>
                  <b-dropdown
                    size="sm"
                    variant="link"
                    toggle-class="text-decoration-none"
                  >
                    <b-dropdown-item @click="deleteCheckpoint(index)">
                      Удалить
                    </b-dropdown-item>
                  </b-dropdown>
                </b-td>
              </b-tr>
            </draggable>
          </b-table-simple>
        </b-col>

        <b-col v-if="checkpointsSettings.length > 0">
          <span>Настройки</span>
          <b-table-simple>
            <b-thead>
              <b-th>Точка</b-th>
              <b-th>Время (секунды)</b-th>
            </b-thead>

            <b-tr
              v-for="(item, index) in checkpointsSettings"
              :key="index"
              class="item-row"
            >
              <b-td>{{ item.point }}</b-td>
              <b-td>
                <b-form-input
                  v-model="item.time"
                  number
                  :state="validateTimeInput(item.time)"
                  type="number"
                  placeholder="Введите время в секундах"
                  aria-describedby="input-feedback"
                  @change="emitData"
                />

                <b-form-invalid-feedback id="input-feedback">
                  Должны быть позитивным числом
                </b-form-invalid-feedback>
              </b-td>
            </b-tr>
          </b-table-simple>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style scoped lang="scss">
.setting-button {
  background: transparent;
  border: none;
}
</style>
