<template>
  <div>
    <b-modal

      id="modal-11"
      ok-only
      ok-title="Ок"
      modal-class="modal-primary"
      centered
      title="Корректировка таблицы"
    >
      <b-table
        :items="manual_correction"
        striped
        responsive
      >
        <template #cell(chip)="data">
          <span class="delete-cheap">

            <svg
              width="16"
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="minus"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              class="svg-inline--fa fa-minus fa-w-14"
              @click="deleteManualCorrection(data)"
            >
              <path
                fill="currentColor"
                d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                class=""
              />
            </svg>
            <span>{{ data.item.chip }}</span>
          </span>

        </template>
      </b-table>
      <b-form-input
        v-model="new_chip"
        type="number"
        class="links__input"
        placeholder="Номер участника"
        style="margin-bottom: 10px;"
      />
      <b-form-input
        v-model="new_field"
        type="text"
        class="links__input"
        placeholder="Название столбца"
        style="margin-bottom: 10px;"
      />
      <b-form-input
        v-model="new_value"
        type="text"
        class="links__input"
        style="margin-bottom: 10px;"
        placeholder="Значение"
      />
      <b-button @click="addCorrection()" variant="primary">
        Добавить
      </b-button>

    </b-modal>
    <b-modal
      id="modal-10"
      ok-only
      ok-title="Ок"
      modal-class="modal-primary"
      centered
      :title="'Отсечки по чипу №' + selected_rowCode "
    >
      <b-table

        :items="selected_row"
        striped
        responsive
      >
        <template #cell(ind)="data">
          <span class="delete-cheap">
            <svg v-if="correction.find(e=>e.ind === data.item.ind)"
                 width="16"
                 aria-hidden="true"
                 focusable="false"
                 data-prefix="fas"
                 data-icon="minus"
                 role="img"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 448 512"
                 class="svg-inline--fa fa-minus fa-w-14"
                 @click="deleteCorrection(data.item.ind)"
            >
              <path
                fill="currentColor"
                d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                class=""
              />
            </svg>
            <span @click="addToCorrection(data.item)">{{data.item.ind}}</span>
            <!--                <span>Ignore</span>-->
          </span>

        </template>
      </b-table>

    </b-modal>
    <input ref="excel-upload-input-2" class="" type="file" accept=".xlsx, .xls"
           @change="handleClick">
    <input ref="excel-upload-input" class="excel-upload-input" type="file" accept=".xlsx, .xls"
           @change="(e)=>handleClick(e, true)">
    <div class="drop" @drop="handleDrop" @dragover="handleDragover" @dragenter="handleDragover">
      Drop excel file here or
      <b-button v-bind:disabled="loading" style="margin-left:16px;" variant="primary" @click="handleUpload">
        <b-spinner v-if="loading" small/>
        Browse
      </b-button>
    </div>
    <h4 class="mt-3">Онлайн трансляция из файла</h4>
    <b-form-group class="time-pick">
      <span>Начало старта</span>
      <flat-pickr

        v-model="start_date"
        class="form-control"
        :config="config"
      />
      Привязать результат к началу старта
      <b-form-checkbox
        v-model="related_start_time"
        class="custom-control-primary"
        name="check-view"
        switch
      />
    </b-form-group>
    <b-form-group
      label="Идентификаторы контрольных точек"
      class="mb-2"
    >
      <b-form-input
        v-model="points"
        class="links__input"
        placeholder="Идентификаторы контрольных точек"
      />
    </b-form-group>
    <b-form-group
      label="Количество контрольных точек"
      class="mb-2"
    >
      <b-form-input
        v-model.number="points_count"
        type="number"
        class="links__input"
        placeholder="Количество контрольных точек"
      />
    </b-form-group>
    <b-form-group
      label="Ссылка на файл для синхронизации"
      class="mb-2"
    >
      <b-form-input
        v-model="sync_url"
        class="links__input"
        placeholder="Ссылка на файл для синхронизации"
      />
    </b-form-group>
    <b-form-group
      label="Частота обновления (секунды)"
      class="mb-2"
    >
      <b-form-input
        v-model.number="frequency"
        type="number"
        class="links__input"
        placeholder="Частота обновления (секунды)"
      />
    </b-form-group>
    <b-form-group
      label="Финиш должен быть не раньше"
      class="mb-2"
    >
      <flat-pickr
        v-model="finish_min"
        class="form-control"
        :config="config"
      />
    </b-form-group>

    <b-button variant="primary" @click="toggleSync">
      <b-spinner v-if="syncing" small/>
      {{syncing? 'Остановить синхронизацию': 'Запустить синхронизацию'}}

    </b-button>

    <b-button style="display: block; margin-top: 10px;" variant="primary"  v-b-modal.modal-11>
      Корректровки
    </b-button>
    <b-table
      :items="table_items"
      striped
      responsive
    >
      <template #cell(chip)="data">
        <span v-b-modal.modal-10 @click="showAllRows(data.item.chip)">{{data.item.chip}}</span>
      </template>
    </b-table>

  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as XLSX from 'xlsx'
import {
  BButton,
  BSpinner,
  BFormInput,
  BTable,
  BFormCheckbox,
  BFormGroup,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'

export default {
  name: "ExcelUploader",
  props: {
            beforeUpload: Function, // eslint-disable-line
            onSuccess: Function,// eslint-disable-line
    runs: Array,
  },
  components: {
    BButton,
    BSpinner,
    BFormInput,
    BFormGroup,
    BTable,
    flatPickr,
    BFormCheckbox,
    ToastificationContent,
  },
  data() {
    return {
      membersData: [],
      ignore_list: [],
      selected_rowCode: null,
      selected_row: null,
      interval_id: null,
      table_items: [],
      all_rows: [],
      start_date: "2022-09-10T07:00:00.000Z",
      related_start_time: false,
      loading: false,
      syncing: false,
      sync_url: 'http://127.0.0.1:8887/3.csv',
      frequency: 30,
      points_count: 15,
      points: 'TRZT1,TRZT2,TRZT3',
      finish_min: '',
      excelData: {
        header: null,
        results: null,
      },
      correction: [],
      new_chip: '',
      new_field: '',
      new_value: '',
      manual_correction: [],
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },
    }
  },
  methods: {
    addToIgnore(item) {
      this.ignore_list.push({
        chip: this.selected_rowCode,
        ind: item.ind,
        stamp: item,
      })
      localStorage.setItem('ignore_list', JSON.stringify(this.ignore_list))
    },
    addCorrection() {
      if (this.new_value && this.new_field && this.new_chip) {
        this.manual_correction.push({chip: this.new_chip, field: this.new_field , value: this.new_value})
        localStorage.setItem('manual_correction', JSON.stringify(this.manual_correction))
      }
    },
    deleteManualCorrection(ind) {
      this.manual_correction.splice(ind.index, 1)
      localStorage.setItem('manual_correction', JSON.stringify(this.manual_correction))
    },
    deleteCorrection(ind) {
      const item = this.correction.findIndex(e => e.ind === ind)

      this.correction.splice(item, 1)

      localStorage.setItem('correction', JSON.stringify(this.correction))
    },
    addToCorrection(item) {
      const circle = prompt("Введите номер отрезка")
      if (circle) {
        this.correction.push({
          chip: this.selected_rowCode,
          circle,
          ind: item.ind,
          stamp: item,
        })
      }

      localStorage.setItem('correction', JSON.stringify(this.correction))
    },
    showAllRows(row) {

      this.selected_rowCode = row
      this.selected_row = this.all_rows[row]

    },
    fillShift(pointFrom, pointTo) {
      let arr = []
      const points = this.points.split(',')
      let ind_from = points.findIndex(e=>e===pointFrom)
      ind_from === points.length - 1 ? ind_from = 0 : ind_from++
      const ind_to = points.findIndex(e=>e===pointTo)
      while (ind_from !== ind_to) {
        arr.push('-')
        ind_from === points.length - 1 ? ind_from = 0 : ind_from++
      }
      return {
        arr,
        ind_to,
      }
    },
    async startParsing() {
      const points = this.points.split(',')
      let members = {}
      const file_data = (await this.readFileFromUrl(this.sync_url)).split('\n').map(e => e.split(';'))
      file_data.forEach((e, i) => {
        const obj = {
          ind: i,
          time: e[1],
          start_timestamp: this.membersData[e[0]] && this.membersData[e[0]].start_timestamp,
          timestamp: this.$moment(e[1], "DD.MM.YYYY HH:mm:ss").format('x'),
          point: e[2],
        }
        if (Array.isArray(members[e[0]])) {
          members[e[0]].push(obj)
        } else {
          members[e[0]] = [obj]
        }
      })
      this.all_rows = members
      let members_result = []

      this.membersData.forEach(e => {

        const member = members[e.chip]

        let member_result = {}
        if (member) {
          const run = this.runs.find(r=>r.name === e.start_date)
          const start_date_start = run ? this.$moment(run.time) : member[0].start_timestamp
          const finish = member.filter(e => ['BACKUP', 'FINISH'].includes(e.point)).filter(e=> start_date_start < e.timestamp && this.$moment(this.finish_min).format('x') <= e.timestamp).sort((a, b) => a.timestamp - b.timestamp)[0]
          let circles_temp = member.filter(e => points.includes(e.point) && start_date_start < e.timestamp).filter((e, i, arr) => !(arr[i - 1] && arr[i - 1].point === e.point))
          let circles = []
          let point_i = 0
          circles_temp.forEach((e, i) => {
            const correction = this.correction.find(item => item.chip === e.chip && +item.circle === (i+1))
            if (correction) {
              circles.push(this.$moment.duration(correction.stamp.timestamp - start_date_start, "milliseconds").format("HH:mm:ss"))
              point_i === points.length - 1 ? point_i = 0 : point_i++
            } else {
              if (e.point === points[point_i]) {
                circles.push(this.$moment.duration(e.timestamp - start_date_start, "milliseconds").format("HH:mm:ss"))
              }
              else {
                const res = this.fillShift(circles_temp[i-1] ? circles_temp[i-1].point : points[points.length-1], e.point)
                circles = circles.concat(res.arr)
                point_i = res.ind_to
                circles.push(this.$moment.duration(e.timestamp - start_date_start, "milliseconds").format("HH:mm:ss"))
              }
              point_i === points.length - 1 ? point_i = 0 : point_i++
            }
            circles[circles.length-1] = circles[circles.length-1].length === 5 ? '00:'+circles[circles.length-1] : circles[circles.length-1]
          })

          member_result = {
            chip: e.chip,
            circles,
            finish: finish ? this.$moment.duration(finish.timestamp - start_date_start, "milliseconds").format("HH:mm:ss") : '-',
          }

          member_result.finish = member_result.finish.length === 5 ? '00:' + member_result.finish : member_result.finish
        }
        else {
          member_result = {
            chip: e.chip,
          }
        }
        if (!member_result.finish) {
          member_result.finish = '-'
        }

        let member_data = {};
        ['name', 'surname', 'group', 'team', 'number', 'distance', 'sex'].forEach(key => {
          member_data[key] = e[key]
        })

        members_result.push({
          ...member_data,
          place: 0,
          ...member_result,
        })
      })

      members_result = members_result.map(item=>{
        let circles = {}
        let i = 0;
        let max = +this.points_count;
        while (max) {
          if (item.circles && item.circles[i]) {
            circles['circle' + (i + 1)] = item.circles[i]
          }
          else {
            circles['circle' + (i + 1)] = '-'
          }
          i++;
          max--;
        }
        return {
          ...item,
          circles: '',
          ...circles,
        }
      })

      this.table_items = members_result.map(e=>{
        const corrections = this.manual_correction.filter(item => item.chip === e.number)
        if (corrections.length > 0) {
          corrections.forEach(correction=>{
            e[correction.field] = correction.value
          })
        }
        return e
      })

      let results_to_upload = this.table_items.map(e=>{
        let circles = {}
        let i = 0;
        let max = +this.points_count;
        while (max) {
          circles['circle' + (i + 1)] = e['circle' + (i + 1)]
          i++;
          max--;
        }
        return {
          name: e.name+' '+e.surname,
          team: e.team,
          body_number: e.number,
          place: e.place,
          group: e.group,
          sex: e.sex,
          distance: e.distance,
          ...circles,
          result: e.finish,
        }
      })

      results_to_upload.filter(e=>e.result && ['-', 'DNF', 'DNS', 'DSQ'].indexOf(e.result) === -1).sort((a,b)=> a.result.replaceAll(':', '') - b.result.replaceAll(':', ''))
        .forEach((e, i)=>{
          e.place = i+1
        })

      this.generateData({
        results: results_to_upload,
      })
    },
    async toggleSync() {
      if (this.membersData.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            icon: 'BellIcon',
            text: 'Не загружен файл с информацией об участниках',
            variant: 'warning',
          },
        })
        return false
      }
      this.syncing = !this.syncing
      clearInterval(this.interval_id)
      if (this.syncing) {
        this.startParsing()
        this.interval_id = setInterval(this.startParsing, this.frequency*1000)
      }
    },
    async readFileFromUrl(url) {
      return await (await fetch(url)).text()
    },
    generateData({header, results}) {
      this.excelData.header = header
      this.excelData.results = results
      this.onSuccess && this.onSuccess(this.excelData)
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()
      if (this.loading) return
      const files = e.dataTransfer.files
      if (files.length !== 1) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            icon: 'BellIcon',
            text: 'Поддерживается загрузка только одного файла',
            variant: 'warning',
          },
        })
        return
      }
      const rawFile = files[0] // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            icon: 'BellIcon',
            text: 'Поддерживаемые форматы: .xlsx, .xls, .csv',
            variant: 'warning',
          },
        })
        return false
      }
      this.upload(rawFile)
      e.stopPropagation()
      e.preventDefault()
    },
    handleDragover(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleUpload() {
      this.$refs['excel-upload-input'].click()
    },
    handleClick(e, flag=false) {
      const files = e.target.files
      const rawFile = files[0] // only use files[0]
      if (!rawFile) return
      this.upload(rawFile, flag)
    },
    upload(rawFile, flag) {
      this.$refs['excel-upload-input'].value = null // fix can't select the same excel
      if (!this.beforeUpload) {
        this.readerData(rawFile, flag)
        return
      }
      const before = this.beforeUpload(rawFile)
      if (before) {
        this.readerData(rawFile, flag)
      }
    },
    readerData(rawFile, flag) {
      this.loading = true
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = XLSX.read(data, {type: 'array'})
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const header = this.getHeaderRow(worksheet)
          const results = XLSX.utils.sheet_to_json(worksheet, {header, raw: false})
          results.shift()
          results.shift()
          if (flag) {
            this.generateData({header, results})
          }
          else {
            let new_results = []
            results.forEach((e, i) => {
              new_results[e.chip] = {
                ...e,
                start_timestamp: this.$moment(e.start_date + ' ' + e.start_time, "DD.MM.YYYY HH:mm:ss").format('x'),
              }
            })
            this.membersData = new_results
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Уведомление',
                icon: 'BellIcon',
                text: 'Данные об участниках загружены',
                variant: 'success',
              },
            })
          }
          this.loading = false
          resolve()
        }
        reader.readAsArrayBuffer(rawFile)
      })
    },
    getHeaderRow(sheet) {
      const headers = []
      const range = XLSX.utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({c: C, r: R})]
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell)
        headers.push(hdr)
      }
      return headers
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },
  },
  mounted() {
    if (localStorage.correction) {
      this.correction = JSON.parse( localStorage.getItem('correction'))
    }
    // if(localStorage.ignore_list){
    //     this.ignore_list = JSON.parse( localStorage.getItem('ignore_list'))
    // }
    if (localStorage.manual_correction) {
      this.manual_correction = JSON.parse( localStorage.getItem('manual_correction'))
    }

  },
  created() {

  },
}
</script>

<style scoped lang="scss">
    .excel-upload-input {
        display: none;
        z-index: -9999;
    }

    .drop {
        border: 2px dashed #bbb;
        width: 600px;
        height: 160px;
        line-height: 160px;
        margin: 0 auto;
        font-size: 24px;
        border-radius: 5px;
        text-align: center;
        color: #bbb;
        position: relative;
    }
    .delete-cheap{
      display: flex;
      svg{
        width: 10px;
        margin-right: 5px;
        transition: .3s;
        &:hover{
          cursor: pointer;
          transform: scale(1.1);
        }
      }
    }
</style>
