<template>
  <div>
    <div class="mt-3">
      Поле {{ fieldIndex + 1 }}
        <b-button
          title="Удалить"
          variant="flat-danger"
          class="btn-icon inline-block"
          size="sm"
          @click="deleteField"
        >
          <feather-icon icon="MinusIcon" />
        </b-button>
    </div>

    <template v-if="fieldState">
      <div class="mt-2 mb-2" :key="'field.name' + fieldIndex">
        Название поля

        <b-form-input
        v-model="fieldState.name"
        placeholder="Название"
        class="inline-block"
        :key="'field.name_' + fieldIndex"
        style="margin-top: 10px!important"
        />
      </div>

      <b-form-checkbox
        default=false
        v-model="fieldState.is_public"
        >Показывать ответы в результатах?
      </b-form-checkbox>

      <div v-if="options.length > 0" class="mt-2 mb-2" :key="'field.parent' + fieldIndex">
        Зависимость от:
        <v-select
          v-model="fieldState.parent_uuid"
          placeholder="Выберите вопрос"
          :reduce="option => option.uuid"
          :options="options"
          label="name"
          style="margin-top: 10px!important"
          @input="changeParent"
        />

        <div v-if="fieldState.parent && fieldState.parent.type === 'Синглселект'" class="mt-1">
          Выберите значение
          <v-select
          style="margin-top: 10px!important"
          v-model="fieldState.expected_answer"
          label="value"
          :options="fieldState.parent.options"
        />
        </div>
      </div>

      <div class="mt-2 mb-2" :key="'field.type' + fieldIndex">
        Тип поля

        <v-select
          style="margin-top: 10px!important"
          v-model="fieldState.type"
          label="value"
          :options="fieldType"
          :reduce="option => option.value"
        />
      </div>
 
      <div v-if="fieldState.type === 'Мультиселект' || fieldState.type === 'Синглселект'" class="mt-2 mb-2" :key="'field.options' + fieldIndex">
        Введите опции

        <v-select
          style="margin-top: 10px!important"
          v-model="fieldState.options"
          taggable 
          multiple
          push-tags
        />
      </div>

      <div v-if="fieldState.type === 'Чекбокс' || fieldState.type === 'Синглселект'" class="mt-2 mb-2" :key="'field.price?' + fieldIndex">
        <b-form-checkbox
            default=false
            v-model="fieldState.priceBoolean"
            @input="resetPrice"
          >Цена?
        </b-form-checkbox>

        <div v-if="fieldState.priceBoolean === true || fieldState.price">
          <b-form-input
            v-model.number="fieldState.price"
            type="number"
            style="margin-top: 10px!important"
            placeholder="Цена"
            class="inline-block"
          />
        </div>
        
      </div>
    </template>
  </div>
</template>

<script>
import { BFormInput, BFormCheckbox, BButton, BFormCheckboxGroup } from 'bootstrap-vue'
import crud_module from '../../../libs/crud_module';
import vSelect from 'vue-select'
import { t } from '@/@core/libs/i18n/utils';

const productsModule = crud_module({
    entity_name: 'product'
  })

export default {
  name: 'additionalField',
  components: {
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormCheckboxGroup,
    vSelect
  },
  data() {
    return {
      fieldState: this.additionalField,
      fieldType: [{name: 'STRING', value: 'Текст'},{name: 'NUMBER', value: 'Число'},{ name: 'BOOLEAN', value: 'Чекбокс'},
      {name: 'DATE', value: 'Дата'},{name: 'MULTI_SELECT', value: 'Мультиселект'},{name: 'SELECT', value: 'Синглселект'},
      {name:'TIME', value: 'Время'}],
      products: [],
      debounce: null,
      options: [],
      showSelect: null
    }
  },
  async created() {
    if (this.fieldState.price) {
      this.fieldState.priceBoolean = true
    }
    if (this.stage) {
      this.options = this.stage.additional_fields.filter(e => e.uuid !== this.fieldState.uuid && (e.type === 'Чекбокс' || e.type === 'Синглселект'))
    } else {
      this.options = this.distance.additional_fields.filter(e => e.uuid !== this.fieldState.uuid && (e.type === 'Чекбокс' || e.type === 'Синглселект'))
    }
    if (this.fieldState.parent_uuid) {
      if (this.stage) {
        this.fieldState.parent = this.stage.additional_fields.filter(field => field.uuid === this.fieldState.parent_uuid)[0]
      } else if (this.distance) {
        this.fieldState.parent = this.distance.additional_fields.filter(field => field.uuid === this.fieldState.parent_uuid)[0]
      }
    }
  },
  props: ['field', 'fieldIndex', 'stage', 'distance'],
  watch: {
    field: {
      handler: function() {
        this.fieldState = this.field
      },
      immediate: true,
    },
    fieldState: {
      handler(newValue, prev) {
        this.$emit('update-field', this.fieldState, this.fieldIndex, this.stage)
      },
      deep: true
    },
  },
  methods: {
    deleteField() { 
      this.$emit('delete-field', this.fieldIndex, this.stage)
    },
    resetPrice() {
      this.fieldState.price = null
    },
    debounceSearch(search, loading, fn) {
      clearTimeout(this.debounce)
      if (search.length > 0) {
        this.debounce = setTimeout(fn, 600)
      }
    },
    getProducts(search, loading) {
      this.debounceSearch(search, loading, async () => {
        try {
          await productsModule.fetchItems({ search: search, limit: 10})
            .then(result => {
              this.products = result.data.rows
              loading(false)
            })
        } catch (e) {
          console.error(e)
        }
      })
    },
    changeParent(e) {
      if (this.stage) {
        const selected = this.stage.additional_fields.filter(field => field.uuid === e)[0]
        this.fieldState.parent = selected
        if (selected?.type === 'Чекбокс') {
          this.fieldState.expected_answer = "true"
        }
      } else {
        const selected = this.distance.additional_fields.filter(field => field.uuid === e)[0]
        this.fieldState.parent = selected
        if (selected?.type === 'Чекбокс') {
          this.fieldState.expected_answer = "true"
        }
      }
    },
  }
}
</script>