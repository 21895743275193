<script>
import * as XLSX from 'xlsx'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BButton, BSpinner } from 'bootstrap-vue'

export default {
  name: 'MemberResultExcelParser',
  components: { BButton, BSpinner },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    handleClick() {
      this.$refs['excel-upload-input'].click()
    },
    handleDrop(e) {
      e.stopPropagation()
      e.preventDefault()

      if (this.loading) {
        return
      }

      const { files } = e.dataTransfer

      if (!files || !files[0]) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            text: 'Не удалось загрузить файл',
            variant: 'warning',
            icon: 'BellIcon',
          },
        })
      }

      const isExcelFile = /\.(xlsx|xls|csv)$/.test(files[0].name)

      if (!isExcelFile) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            text: 'Поддерживаемые форматы: .xlsx, .xls, .csv',
            variant: 'warning',
            icon: 'BellIcon',
          },
        })

        return
      }

      this.uploadProcess(files[0])

      e.stopPropagation()
      e.preventDefault()
    },
    handleDrag(e) {
      e.stopPropagation()
      e.preventDefault()
      e.dataTransfer.dropEffect = 'copy'
    },
    handleUpload(e) {
      const { files } = e.target

      if (!files || !files[0]) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            text: 'Не удалось загрузить файл',
            variant: 'warning',
            icon: 'BellIcon',
          },
        })
      }

      this.uploadProcess(files[0])
    },
    uploadProcess(excelFile) {
      this.loading = true

      const fileReader = new FileReader()
      fileReader.onload = this.parseExcel
      fileReader.readAsArrayBuffer(excelFile)

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Уведомление',
          text: 'Данные загружены',
          variant: 'success',
          icon: 'BellIcon',
        },
      })
    },
    parseExcel(loadEvent) {
      const data = loadEvent.target.result
      const workbook = XLSX.read(data, { type: 'array' })
      const firstSheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[firstSheetName]
      const header = this.getHeaders(worksheet)
      const results = XLSX.utils.sheet_to_json(worksheet, {
        header,
        raw: false,
      })

      if (!Array.isArray(results) || results.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Предупреждение',
            text: 'Данные отсутствуют',
            variant: 'warning',
            icon: 'BellIcon',
          },
        })
        this.loading = false
        return
      }

      results.splice(0, 2)

      const parsedResults = []
      results.forEach(item => {
        const { start_date, start_time, chip } = item
        const timestamp = this.$moment(
          `${start_date} ${start_time}`,
          'DD.MM.YYYY HH:mm:ss',
        ).format('x')

        parsedResults.push({
          ...item,
          chip: chip.split(','),
          start_timestamp: timestamp,
        })
      })

      this.$emit('onExcelParsed', parsedResults.filter(Boolean))
      this.loading = false
    },
    getHeaders(sheet) {
      const headers = []
      const range = XLSX.utils.decode_range(sheet['!ref'])
      const {
        s: { r: startRow },
        e: { c: endColumn },
      } = range

      for (let column = range.s.c; column <= endColumn; column++) {
        const cell = sheet[XLSX.utils.encode_cell({ c: column, r: startRow })]
        const header = cell && cell.t ? XLSX.utils.format_cell(cell) : `UNKNOWN ${column}`
        headers.push(header)
      }

      return headers
    },
  },
}
</script>

<template>
  <div>
    <input
      ref="excel-upload-input"
      class="excel-upload-input"
      type="file"
      accept=".xlsx, .xls"
      hidden="hidden"
      @change="handleUpload"
    >

    <div
      class="drop"
      @drop="handleDrop"
      @dragover="handleDrag"
      @dragenter="handleDrag"
    >
      Перетащите excel файлы сюда
      <b-button
        :disabled="loading"
        class="upload-button"
        variant="primary"
        @click="handleClick"
      >
        <b-spinner
          v-if="loading"
          small
        />
        Выбрать файлы
      </b-button>
    </div>
  </div>
</template>

<style scoped lang="scss">
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;

  .upload-button {
    margin-left: 16px;
  }
}
</style>
