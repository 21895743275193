<template>
  <div>
    <b-button 
      variant="primary"
      class="mr-1 mb-2"
      v-b-modal.modal-2
    >
      Загрузить из шаблона
    </b-button>
    <b-modal
      ref="group-modal"
      id="modal-2"
      title="Basic Modal"
      hide-footer
      no-close-on-backdrop
    >
      <v-select
        v-model="selected"
        label="name"
        :options="groupTemplates"
      />
      <b-button 
        variant="primary"
        class="mr-1 mt-2"
        @click="updateGroup"
      >
        Сохранить
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup,BButton } from 'bootstrap-vue'
import vSelect from 'vue-select'

import Ripple from 'vue-ripple-directive'
import crud_module from "@/libs/crud_module";

const group_module = crud_module({
  entity_name: 'group-template'
})
export default {
  name: "GroupTemplates",
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  data(){
    return{
      groupTemplates: [],
      selected: {
        name: ""
      }
    }
  },
  methods: {
    async getTemplates(){
      this.groupTemplates = await group_module.fetchItems().then(r => r.data.rows)
    },
    updateGroup(){
      if(!this.selected){
        return false
      }
      console.log('parse====', JSON.parse(this.selected.code))
      this.$emit("select", JSON.parse(this.selected.code))
      this.$refs['group-modal'].hide()
    }
  },
  created() {
    this.getTemplates()
  }
}
</script>
