<template>
  <div>
    <b-modal
      v-model="activeSelectSport"
      class="z-index"
      title="Добавить новый вид спорта"
      ok-only
      ok-title="Добавить"
      @ok="addNewSport"
    >
      <div class="con-example-prompt">
        <b-form-input
          v-model="new_kind_sport"
          placeholder="Новый вид спорта"
          label="Новый вид спорта"
          vs-placeholder="Новый вид спорта"
          class="w-full"
        />




      </div>
    </b-modal>

    <b-row>
      <b-col
        vs-type="flex"
        vs-justify="center"
        vs-align="flex-start"
        vs-xs="12"
        vs-lg="6"
      >
        <div
          style="width: 100%"
          class="p-6 pt-0"
        >
          <div>Выбранные виды спорта и количество этапов</div>
          <div
            v-for="(sport, index) in select_kinds_sport"
            class="sport-block"
          >
            <b-modal
              v-model="sport.prompt"
              class="z-index"
              title="Дополнительная информация"
              ok-only
            >

              <div
                v-if="sport.type !== 'multisport'"
                class="con-example-prompt"
              >
                <div class="mt-3 mb-3 title-format">
                  Формат
                </div>
                <div
                  v-for="(format, index) in kinds_format_sport"
                  :key="'format'+index"
                  class="mb-1"
                >
                  <b-form-radio
                    v-model="sport.format"
                    name="sub_kind_sport"
                    class="mr-6"
                    :value="format"
                  >
                    {{ format }}
                  </b-form-radio>
                </div>
                <template v-if="sport.format.indexOf('Лично') !== -1">
                  <div
                    v-for="(distance, num) in sport.distances"
                    class="distance"
                  >
                    <div>Дистанция №{{ num+1 }}

                      <svg
                        class="distances__minus"
                        width="16"
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="minus"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        @click="sport.distances.splice(num,1)"
                      >
                        <path
                          fill="currentColor"
                          d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"
                          class=""
                        />
                      </svg>
                    </div>
                    <b-form-input
                      v-model="distance.description"
                      placeholder="Дистанция"
                      vs-placeholder="Дистанция"
                      class="inline-block mt-3"
                    />
                    <b-form-input
                      v-model="distance.price"
                      placeholder="Цена"
                      vs-placeholder="Цена"
                      class="inline-block mt-3"
                    />
                    <div class="price">
                      <h4 class="price__title">
                        Доп. цены
                      </h4>
                      <div class="price__items">

                        <div
                          v-for="(item, i) in distance.prices"
                          :key="'price_' +i"
                          class="price__item"
                        >
                          <div
                            class="price__item-del"
                          >
                            <svg
                              width="10"
                              style="cursor:pointer"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512"
                              @click="distance.prices.splice(i,1)"
                            ><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" /></svg>
                          </div>
                          <flat-pickr
                            v-model="item.c_from"
                            placeholder="От"
                            class="form-control"
                            :config="config"
                          />
                          <flat-pickr
                            v-model="item.c_to"
                            placeholder="До"
                            class="form-control"
                            :config="config"
                          />
                          <b-form-input
                            v-model.number="item.value"
                            type="number"
                            placeholder="Цена"
                          />
                        </div>
                      </div>
                      <b-button

                        type="submit"
                        variant="primary"
                        class="mr-1"
                        @click="addPrices(distance)"
                      >
                        Добавить цену
                      </b-button>
                    </div>

                    <div class="inline-block">

                      <b-form-input
                        v-model="distance.slots"
                        :disabled="distance.infinitySlot"
                        placeholder="Кол-во слотов"
                        label="Слотов:"
                        class="full-width"
                      />
                      <div
                        class="flex mt-2 mb-3 "
                        style="align-items: center"
                      >
                        <b-form-checkbox
                          v-model="distance.infinitySlot"
                          class="custom-control-primary"
                          checked="false"
                          name="check-reg"
                          switch
                        />
                        <span class="ml-1">Неограниченное кол-во слотов</span>

                      </div>

                    </div>
                    <div class="add-fille">
                      Прикрепить файл трека
                    </div>
                    <!--                                        <vs-upload text="Файл трека" fileName="file" :headers="axiosHeader"-->
                    <!--                                                   single-upload automatic-->
                    <!--                                                   action="/api/files/add"-->
                    <!--                                                   @on-success="(data)=>{uploadFile(data,distance)}"/>-->
                    <file-uploader
                      v-model="distance.track_link"
                      title="Загрузка файла"
                    />
                    <b-form-input
                      v-model="distance.track_code"
                      placeholder="Код виджета трека"
                      class="mt-5"
                    />

                    <div class="mt-3 mb-3">
                      Группы ({{ distance.groups.length }})
                    </div>
                    <group-templates @select="(group)=>{distance.groups=group}" />
                    <div
                      v-for="(group, i) in distance.groups"
                      :key="'sport.groups_'+index+'_'+i"
                    >
                      <div>Группа {{ i+1 }}

                        <b-button
                          title="Удалить"
                          variant="flat-danger"
                          class="btn-icon inline-block"
                          size="sm"
                          @click="distance.groups.splice(i,1)"
                        >
                          <feather-icon icon="MinusIcon" />
                        </b-button>

                      </div>
                      <b-form-input
                        v-model="group.name"
                        placeholder="Название"
                        class="inline-block"
                      />
                      <div class="inline-block">
                        <b-form-checkbox
                          v-model="group.sex"
                          class="mt-1 mr-1"
                          value="male"
                        >М
                        </b-form-checkbox>
                      </div>
                      <div class="inline-block">
                        <b-form-checkbox
                          v-model="group.sex"
                          value="female"
                        >Ж
                        </b-form-checkbox>
                      </div>
                      <div style="margin-top: 40px">
                        Возраст
                      </div>
                      <div class="age">
                        <b-form-input
                          v-model="group.ageFrom"
                          style="margin-top: 10px!important"
                          placeholder="От"
                          class="inline-block"
                        />
                        <b-form-input
                          v-model="group.ageTo"
                          style="margin-top: 10px!important"
                          placeholder="До"
                          class="inline-block"
                        />
                      </div>
                    </div>
                    <b-button
                      class="mt-3"
                      size="small"
                      @click="distance.groups.push({name: '', sex: [], ageFrom:'', ageTo: '',})"
                    >Добавить группу
                    </b-button>

                    <!--                                        <strong>М: </strong>
                                        <div class="inline-block text-center"
                                             v-for="(age, index) in age_groups.male"
                                             :key="'allowed_age_m'+index">
                                            <div>{{index+1}}</div>
                                            <b-form-checkbox class="" :vs-value="index"
                                                             v-model="distance.allowed_age.male"></b-form-checkbox>
                                        </div>
                                        <strong>Ж: </strong>
                                        <div class="inline-block text-center"
                                             v-for="(age, index) in age_groups.female"
                                             :key="'allowed_age_f'+index">
                                            <div>{{index+1}}</div>
                                            <b-form-checkbox class="" :vs-value="index"
                                                             v-model="distance.allowed_age.female"></b-form-checkbox>
                                        </div>-->
                  </div>
                  <div>
                    <b-button
                      class="mt-3"
                      size="small"
                      @click="addDistance(index)"
                    >Добавить дистанцию
                    </b-button>
                  </div>
                </template>
                <template v-if="sport.format.indexOf('Эстафета') !== -1">
                  <div class="mt-3 mb-3">
                    Группы ({{ sport.groups.length }})
                  </div>
                  <group-templates @select="(group)=>{sport.groups=group}" />
                  <div
                    v-for="(group, i) in sport.groups"
                    :key="'sport.groups_'+index+'_'+i"
                  >
                    <div>Группа {{ i+1 }}

                      <b-button
                        title="Удалить"
                        variant="flat-danger"
                        class="btn-icon inline-block"
                        size="sm"
                        @click="sport.groups.splice(i,1)"
                      >
                        <feather-icon icon="MinusIcon" />
                      </b-button>
                    </div>

                    <b-form-input
                      v-model="group.name"
                      placeholder="Название"
                      class="inline-block"
                    />
                    <div class="inline-block mt-1 mr-1">
                      <b-form-checkbox
                        v-model="group.sex"
                        value="male"
                      >М
                      </b-form-checkbox>
                    </div>
                    <div class="inline-block mt-1">
                      <b-form-checkbox
                        v-model="group.sex"
                        value="female"
                      >Ж
                      </b-form-checkbox>
                    </div>
                    <div style="margin-top: 40px">
                      Возраст
                    </div>
                    <div class="age">
                      <b-form-input
                        v-model="group.ageFrom"
                        style="margin-top: 10px!important"
                        placeholder="От"
                        class="inline-block"
                      />
                      <b-form-input
                        v-model="group.ageTo"
                        style="margin-top: 10px!important"
                        placeholder="До"
                        class="inline-block"
                      />
                    </div>
                  </div>
                  <b-button
                    class="mt-3"
                    size="small"
                    @click="sport.groups.push({name: '', sex: [],ageFrom:'', ageTo: '',})"
                  >Добавить группу
                  </b-button>
                  <div>
                    <b-form-input
                      v-model="sport.price"
                      placeholder="Цена"
                      vs-placeholder="Цена"
                      class="inline-block mt-3"
                    />
                    <div class="inline-block">

                      <b-form-input
                        v-model="sport.slots"
                        placeholder="Кол-во слотов"
                        label="Слотов:"
                        class="full-width mt-3"
                      />
                    </div>
                  </div>
                  <div class="price">
                    <h4 class="price__title">
                      Доп. цены
                    </h4>
                    <div class="price__items">

                      <div
                        v-for="(item, i) in sport.prices"
                        :key="'price_' +i"
                        class="price__item"
                      >
                        <div
                          class="price__item-del"
                        >
                          <svg
                            width="10"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                            style="cursor:pointer"
                            @click="sport.prices.splice(i,1)"
                          ><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" /></svg>
                        </div>
                        <flat-pickr
                          v-model="item.c_from"
                          placeholder="От"
                          class="form-control"
                          :config="config"
                        />
                        <flat-pickr
                          v-model="item.c_to"
                          placeholder="До"
                          class="form-control"
                          :config="config"
                        />
                        <b-form-input
                          v-model.number="item.value"
                          type="number"
                          placeholder="Цена"
                        />
                      </div>
                    </div>
                    <b-button

                      type="submit"
                      variant="primary"
                      class="mr-1"
                      @click="addPrices(sport)"
                    >
                      Добавить цену
                    </b-button>
                  </div>
                  <div class="mt-3 mb-3">
                    Этапы ({{ sport.stages.length }})
                  </div>
                  <div
                    v-for="(stage, i) in sport.stages"
                    :key="'stage_'+index+'_'+i"
                  >
                    <div>Этап {{ i+1 }}
                      <b-button
                        title="Удалить"
                        variant="flat-danger"
                        class="btn-icon inline-block"
                        size="sm"
                        @click="sport.stages.splice(i,1)"
                      >
                        <feather-icon icon="MinusIcon" />
                      </b-button>

                    </div>
                    <div class="inline-block">
                      <b-form-checkbox
                        v-model="stage.male"
                        :value="index"
                      >М
                      </b-form-checkbox>
                    </div>
                    <b-form-input
                      v-model="stage.value_m"
                      placeholder="Величина"
                      vs-placeholder="Величина"
                      class="inline-block"
                    />
                    <div class="inline-block mt-1">
                      <b-form-checkbox
                        v-model="stage.female"
                        :value="index"
                      >Ж
                      </b-form-checkbox>
                    </div>
                    <b-form-input
                      v-model="stage.value_f"
                      placeholder="Величина"
                      vs-placeholder="Величина"
                      class="inline-block"
                    />
                  </div>
                  <b-button
                    class="mt-3"
                    size="small"
                    @click="sport.stages.push({
                      male: [], female: [], value_m:'',value_f: '',
                      pirces: {
                        c_to: null,
                        c_from: null,
                        value: null,
                      },
                      price: '', slots: 0})"
                  >Добавить этап
                  </b-button>
                </template>
              </div>
              <div
                v-else
                class="con-example-prompt"
              >
                <b-form-input
                  v-model="sport.price"
                  placeholder="Цена"
                  vs-placeholder="Цена"
                  class="inline-block mt-3"
                />
                <div class="inline-block">
                  <b-form-input
                    v-model="sport.slots"
                    placeholder="Кол-во слотов"
                    label="Слотов:"
                    min="1"
                    class="full-width mt-3"
                  />
                </div>
                <template v-if="sport.format.indexOf('Лично') !== -1">
                  <div>Разрешить группам</div>
                  <strong>М: </strong>
                  <div
                    v-for="(age, index) in age_groups.male"
                    :key="'allowed_age_m'+index"
                    class="inline-block text-center"
                  >
                    <div>{{ index+1 }}</div>
                    <b-form-checkbox
                      v-model="sport.allowed_age.male"
                      class=""
                      :value="index"
                    />
                  </div>
                  <strong>Ж: </strong>
                  <div
                    v-for="(age, index) in age_groups.female"
                    :key="'allowed_age_f'+index"
                    class="inline-block text-center"
                  >
                    <div>{{ index+1 }}</div>
                    <b-form-checkbox
                      v-model="sport.allowed_age.female"
                      class=""
                      :value="index"
                    />
                  </div>
                </template>
                <template v-if="sport.format.indexOf('Эстафета') !== -1">
                  <div class="mt-3 mb-3">
                    Группы ({{ sport.groups.length }})
                  </div>
                  <group-templates @select="(group)=>{sport.groups=group}" />
                  <div
                    v-for="(group, i) in sport.groups"
                    :key="'sport.groups_'+index+'_'+i"
                  >
                    <div>Группа {{ i+1 }}
                      <b-button
                        title="Удалить"
                        class="inline-block"
                        color="primary"
                        type="flat"
                        icon-pack="feather"
                        icon="icon-minus"
                        @click="sport.groups.splice(i,1)"
                      />
                    </div>
                    <b-form-input
                      v-model="group.name"
                      placeholder="Название"
                      class="inline-block"
                    />
                    <div class="inline-block">
                      <b-form-checkbox
                        v-model="group.sex"
                        value="male"
                      >М
                      </b-form-checkbox>
                    </div>
                    <div class="inline-block">
                      <b-form-checkbox
                        v-model="group.sex"
                        value="female"
                      >Ж
                      </b-form-checkbox>
                    </div>
                    <div style="margin-top: 40px">
                      Возраст
                    </div>
                    <div class="age">
                      <b-form-input
                        v-model="group.ageFrom"
                        style="margin-top: 10px!important"
                        placeholder="От"
                        class="inline-block"
                      />
                      <b-form-input
                        v-model="group.ageTo"
                        style="margin-top: 10px!important"
                        placeholder="До"
                        class="inline-block"
                      />
                    </div>
                  </div>
                  <b-button
                    class="mt-3"
                    size="small"
                    @click="sport.groups.push({name: '', sex: [],ageFrom:'', ageTo: '',})"
                  >Добавить группу
                  </b-button>

                </template>
              </div>
            </b-modal>
            <div
              :key="'kinds_multi_sport'+index"
              class="flex items-center mt-2 mb-2"
              :class="{'justify-end':sport.type === 'multisport'}"
            >
              <span :class="{'mr-auto':sport.type === 'multisport'}">
                <b-button
                  title="Удалить"
                  variant="flat-danger"
                  class="btn-icon inline-block"
                  size="sm"
                  @click="deleteSport(index)"
                >
                  <feather-icon icon="MinusIcon" />
                </b-button>
                <strong>{{ sport.value }} <span v-if="sport.sub && sport.type !== 'multisport'">({{ sport.sub }})</span></strong>
                <b-button
                  variant="flat-warning"
                  class="btn-icon inline-block"
                  size="sm"
                  @click="sport.prompt=!sport.prompt"
                >
                  <feather-icon icon="InfoIcon" />
                </b-button>
              </span>
            </div>

          </div>
          <b-button
            class="mt-3"
            variant="primary"
            @click="activeSelectSport=true"
          >Добавить вид спорта
          </b-button>
        </div>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BSpinner, BModal, BFormRadio,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
import FileUploader from '../../global-components/FileUploader'
import GroupTemplates from '@/views/starts/GroupTemplates'

export default {
  name: 'StartDays',
  components: {
    GroupTemplates,
    FileUploader,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    flatPickr,
    BFormRadio,

  },
  props: ['age_groups', 'select_kinds_sport'],
  data() {
    return {
      config: {
        allowInput: true,
        altFormat: 'd.m.Y H:i',
        altInput: true,
        enableTime: true,
        dateFormat: 'Z',
        locale: Russian, // locale for this instance only
      },

      slotDisable: false,
      test: [],
      kind_sport: '',
      sub_kind_sport: '',
      multi_sport_name: '',
      kinds_multi_sport: [],
      select_kinds_format_sport: [],
      activeSelectSport: false,
      new_kind_sport: '',
      new_sub_kind_sport: '',
      multi_kind_sport: [],
      kinds_sport: [
        {
          value: 'Бег',
          sub: ['Кросс (трейл)', 'Шоссе'],
          stages: [],
          format: '',
        },
        {
          value: 'Плавание',
          sub: ['Открытая вода', 'Бассейн'],
          stages: [],
          format: '',
        },
        {
          value: 'Лыжи',
          sub: ['Классический стиль', 'Свободный стиль'],
          stages: [],
          format: '',
        },
        {
          value: 'Вело',
          sub: ['Кросс-кантри', 'Шоссе'],
          stages: [],
          format: '',
        },
      ],
      kinds_format_sport: [
        'Лично',
        'Эстафета',
      ],

    }
  },
  computed: {
    sub_kinds_sport() {
      if (this.kind_sport) {
        const t = this
        let result = []
        t.sub_kind_sport = ''
        t.kinds_sport.some(e => {
          if (e.value === t.kind_sport) {
            result = e.sub
          }
        })
        return result
      }
    },
  },
  watch: {},
  created() {

  },
  mounted() {

  },
  methods: {
    addPrices(distance) {
      if (!distance.prices) {
        this.$set(distance, 'prices', [])
      }
      distance.prices.push({ c_to: '', c_from: '', value: '' })
    },
    uploadFile(data, distance) {
      this.$set(distance, 'track_link', JSON.parse(data.target.response).url)
    },
    addStage(i) {
      this.select_kinds_sport[i].stages.push({
        male: [],
        female: [],
        sports: [],
      })
    },
    deleteStage(i) {
      this.select_kinds_sport[i].stages.splice(i, 1)
    },
    deleteSport(i, i_m = -1) {
      if (i_m >= 0) {
        this.select_kinds_sport[i].sub.splice(i_m, 1)
      } else {
        this.select_kinds_sport.splice(i, 1)
      }
    },
    addDistance(i, i_m = -1) {
      if (i_m >= 0) {
        this.select_kinds_sport[i].sub[i_m].distances.push({
          description: '',
          price: '',
          prices: [

          ],
          slots: 1,
          infinitySlots: false,
          track_link: {},
          track_code: '',
          groups: [],
        })
      } else {
        this.select_kinds_sport[i].distances.push({
          description: '',
          price: '',
          prices: [],
          slots: 1,
          infinitySlots: false,
          track_link: {},
          track_code: '',
          format: [],
          groups: [],
        })
      }
    },
    addNewSport() {
      if (this.new_kind_sport) {
        this.kinds_sport.push({
          value: this.new_kind_sport,
          prompt: false,
          distances: [],
          groups: [],
          stages: [],
          format: [],
        })
        this.select_kinds_sport.push({
          value: this.new_kind_sport,
          prompt: false,
          distances: [],
          groups: [],
          stages: [],
          format: [],

        })
      } else if (this.multi_sport_name) {
        this.select_kinds_sport.push({
          value: this.multi_sport_name,
          type: 'multisport',
          prompt: false,
          price: '',
          prices: [],
          slots: 1,
          allowed_age: {
            male: [],
            female: [],
          },
          groups: [],
          sub: this.multi_kind_sport.map(value => ({
            value,
            stages: [],
            prompt: false,
            distances: [],
          })),
          stages: [
            {
              male: [],
              female: [],
              sports: [],
            },
          ],
          format: [],
        })
      } else if (this.new_sub_kind_sport) {
        this.select_kinds_sport.push({
          value: this.kind_sport,
          sub: this.new_sub_kind_sport,
          prompt: false,
          distances: [],
          groups: [],
          stages: [],
          format: [],
        })
      } else {
        this.select_kinds_sport.push({
          value: this.kind_sport,
          sub: this.sub_kind_sport,
          prompt: false,
          distances: [],
          groups: [],
          stages: [],
          format: [],
        })
      }
      this.new_kind_sport = ''
      this.new_sub_kind_sport = ''
      this.multi_sport_name = ''
      this.multi_kind_sport = []
    },
  },
}
</script>

<style lang="scss" scoped>

.add-fille{
  margin: 10px 0;
}
.distance{
  margin-top: 30px;
}
.title-format{
  margin: 15px 0!important;
}
.input__code{
  margin-top: 15px!important;
}
.con-example-prompt{
  input{
    margin-top: 20px!important;
  }
}
.distances__minus{
  margin-left: 5px;
  transition: .3s;
  &:hover{
    cursor: pointer;
    transform: scale(1.1);
  }
}
    .sport-block {
        border-bottom: 1px solid #e5e5e5;
    }

    .z-index {
        z-index: 52011;
    }

.price{
  &__title{
    margin-top: 20px;
  }
  &__items{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 20px -15px;
  }
  &__item{
    padding: 20px;
    border: 1px solid #00000012;
    border-radius: 20px;
    position: relative;
    width: calc(50% - 30px);
    margin: 10px 15px 0 15px;

    &-del{
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }

}

</style>
<style>
.flex{
  display: flex;

}
.justify-end{

  justify-content: end;
}
.inline-block{
  display: inline-block;
}
.age{
  display: flex;

}
.age input:last-child{
  margin-left: 30px;
}
.price__item .form-control{
  margin-top: 20px;
}
</style>
