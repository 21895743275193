
<template>
  <div>
    <div
      name="stage"
      class="mt-2"
      :key="'stage.value' + stageIndex"
    >
      {{ 'Этап ' + stage.name }}
    </div>

    <b-form-checkbox-group
      v-if="groupType"
      :value="combination_dict[stage.uuid] || setReactiveProperty(combination_dict, stage.uuid, [])"
      v-model="combination_dict[stage.uuid]"
      name="stage"
      @change="setStageState"
    >
      <b-form-checkbox
        class="mt-1 mr-1"
        value="Male"
        :key="'stage.sex.male' + stageIndex"
      > М </b-form-checkbox>
      <b-form-checkbox
        value="Female"
        :key="'stage.sex.female' + stageIndex"
      > Ж </b-form-checkbox>
    </b-form-checkbox-group>
  </div>
</template>
<script>
import { BFormInput, BFormCheckbox, BButton, BFormCheckboxGroup } from 'bootstrap-vue'
import Vue from 'vue'

export default {
  name: 'stage',
  components: {
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormCheckboxGroup,
  },
  props: ['stage', 'stageIndex', 'groupType', 'combination_dict'],
  data() {
    return {
    }
  },
  // created() {
  //   console.log('Stage', this.stage, this.combination_dict)
  // },
  methods: {
    setReactiveProperty(obj, key, value) {
      Vue.set(obj, key, value)
      return obj[key]
    },
    setStageState(e) {
      this.combination_dict[this.stage.uuid] = e
    },
  },
}
</script>
