<template>
  <div v-if="stages">
    <template v-for="(stage, i) in stages">
      <div
        :key="'stage_' + i"
        class="mt-2"
      >Этап {{ i + 1 }}
        <b-button
          title="Удалить"
          variant="flat-danger"
          class="btn-icon inline-block"
          size="sm"
          @click="deleteStage(stage)"
        >
          <feather-icon icon="MinusIcon" />
        </b-button>
      </div>
      <b-form-input
        :key="'stage.name_' + i"
        v-model="stage.name"
        placeholder="Название"
        vs-placeholder="Название"
        class="inline-block mb-1"
      />
      <v-select
        :key="'stage.kind_of_sport_id_' + i"
        v-model="stage.kind_of_sport_id"
        :options="kindOfSports"
        label="name"
        class="mb-1"
        :reduce="option => option.id"
        placeholder="Вид спорта"
      />
      <b-form-input
        :key="'stage.sex_' + i"
        v-model="stage.sex"
        placeholder="Укажите пол"
        vs-placeholder="Укажите пол"
        class="inline-block mb-1"
      />
      <b-form-input
        :key="'stage.stage_length_' + i"
        v-model="stage.stage_length"
        placeholder="Величина"
        vs-placeholder="Величина"
        class="inline-block mb-1"
      />

      <template>
        <div>
          <div class="mt-1 mb-1 mt-2">
            Дополнительные поля ({{ stage.additional_fields.length }})
          </div>
          <template v-for="(additionalField, index) in stage.additional_fields">
            <additionalField
              :key="'additionalField' + index"
              :stage="stage"
              :field="additionalField"
              :field-index="index"
              @update-field="updateField"
              @delete-field="deleteField"
            />
          </template>
          <b-button
            class="mt-1"
            size="small"
            @click="addField(stage)"
          >
            Добавить доп поле
          </b-button>
        </div>
      </template>

    </template>
  </div>
</template>

<script>
import {
  BFormInput, BFormCheckbox, BButton, BFormCheckboxGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { v4 as uuidv4 } from 'uuid'
import crud_module from '../../../libs/crud_module'
import AdditionalField from './AdditionalField.vue'

const kind_of_sports = crud_module({
  entity_name: 'kind-of-sport',
})

export default {
  name: 'Stages',
  components: {
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormCheckboxGroup,
    vSelect,
    AdditionalField,
  },
  props: ['stages', 'restirctedStages'],
  data() {
    return {
      kindOfSports: [],
    }
  },
  created() {
    this.getKindOfSports()
  },
  methods: {
    async getKindOfSports() {
      const data = await kind_of_sports.fetchItems()
      this.kindOfSports = data.data && data.data.rows
    },
    addField(stage) {
      const uuid = uuidv4()
      stage.additional_fields.push({
        name: '',
        type: '',
        uuid,
      })
    },
    updateField(field, fieldIndex, stage) {
      stage.additional_fields[fieldIndex] = field
    },
    deleteField(fieldIndex, stage) {
      stage.additional_fields.splice(fieldIndex, 1)
    },
    deleteStage(stage) {
      this.$emit('deleteStage', stage)
    },
  },
}
</script>
<style lang="scss" scoped>
.vs__dropdown-menu {
  top: 0;
}
</style>
