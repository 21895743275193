<template>
  <div>
    <b-table
      small
      :fields="fields"
      :items="transactions.rows"
      :tbody-tr-class="rowClass"
      responsive="sm"
    >
      <template #cell(amount)="data">
        {{ data.value/100 }}
      </template>
    </b-table>

    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      type="submit"
      variant="primary"
      class="mr-1 save-button"
      :disabled="isloading"
      @click="fetchTransactions"
    >
      <b-spinner
        v-if="isloading"
        small
      />
      {{ isloading ? 'Запрос в банк...' : 'Запросить информацию из банка' }}
    </b-button>

    Итого: {{ transactions.sum || 0 }} руб.
  </div>
</template>

<script>
import {
  BTable, BProgress, BBadge, BSpinner, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'StartStatistic',
  components: {
    BTable,
    BProgress,
    BBadge,
    BSpinner,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    start_id: Number,
  },
  data() {
    return {
      isloading: false,
      fields: [
        { key: 'orderNumber', label: 'Номер заказа' },
        { key: 'payerData.email', label: 'Email' },
        { key: 'bankInfo.bankName', label: 'Банк' },
        { key: 'actionCodeDescription', label: 'Статус' },
        { key: 'amount', label: 'Сумма' },
      ],
      transactions: {},
    }
  },
  methods: {
    rowClass(item, type) {
      const colorClass = 'table-success'
      if (!item || type !== 'row') {
        return
      }
      if (item.actionCode === 0) {
        return colorClass
      }
    },
    async fetchTransactions() {
      this.isloading = true
      try {
        this.transactions = await this.$http.get(`/member-start/${this.start_id}/transactions`).then(r => r.data)
        this.isloading = false
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Уведомление',
            icon: 'BellIcon',
            text: 'Ошибка получения информации из сбербанка',
            variant: 'danger',
          },
        })
        this.isloading = false
      }
    },
  },
}
</script>

<style scoped>

</style>
