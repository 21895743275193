<template>
  <div>
    <b-modal
      id="bv-modal-create"
      class="z-index"
      title="Добавить новую дистанцию"
      ok-only
      ok-title="Добавить"
      @ok="addNewSport"
      @hidden="resetModal"
    >
      <div class="con-example-prompt">
        <b-form-input
          v-model="distance.name"
          placeholder="Новая дистанция"
          label="Новая дистанция"
          vs-placeholder="Новая дистанция"
          class="w-full"
        />

        <div class="mt-3 mb-3 title-format">
          Формат
        </div>
        <div
          v-for="(format, index) in sport_formats"
          :key="'format' + index"
          class="mb-1"
        >
          <b-form-radio
            v-model="distance.format"
            name="sport_format"
            class="mr-6"
            :value="format"
            @input="selectFormat"
          >
            {{ format }}
          </b-form-radio>
        </div>
      </div>

      <template v-if="distance.format === 'Лично'">
        <div>
          <distance-block
            :distance="distance"
            :mode="'Лично'"
          />
          <div class="mt-3 mb-3 mt-3">
            Группы ({{ distance.groups.length }})
          </div>
          <group-templates @select="(group) => {groups = group}" />
          <template v-for="(group, index) in distance.groups">
            <group
              :key="'group' + index"
              :groups="distance.groups"
              :group="group"
              :group-index="index"
              @update-group="updateGroup"
              @delete-group="deleteGroup"
            />
          </template>
          <b-button
            class="mt-2"
            size="small"
            @click="addGroup"
          >
            Добавить группу
          </b-button>
        </div>
      </template>

      <template v-if="distance.format === 'Эстафета'">
        <distance-block :distance="distance" />
        <div class="mt-3">
          Этапы ({{ distance.stages.length }})
        </div>
        <stage-block
          :stages="distance.stages"
          :restircted-stages="[]"
          @deleteStage="deleteStage"
        />
        <b-button
          class="mt-2"
          size="small"
          @click="addStage"
        >
          Добавить этап
        </b-button>

        <div class="mt-3 mb-3 mt-3">
          Группы ({{ distance.groups.length }})
        </div>
        <group-templates @select="(group) => {groups = group}" />
        <template v-for="(group, index) in distance.groups">
          <group
            :key="'group' + index"
            :groups="distance.groups"
            :group="group"
            :group-index="index"
            :distance-format="distance.format"
            :stages="distance.stages"
            @update-group="updateGroup"
            @delete-group="deleteGroup"
          />
        </template>

        <b-button
          class="mt-2"
          size="small"
          @click="addGroup"
        >
          Добавить группу
        </b-button>
      </template>

      <template>
        <div>
          <div class="mt-1 mb-1 mt-2">
            Дополнительные поля ({{ distance.additional_fields.length }})
          </div>
          <template v-for="(additionalField, index) in distance.additional_fields">
            <additionalField
              :key="'additionalField' + index"
              :distance="distance"
              :field="additionalField"
              :field-index="index"
              @update-field="updateField"
              @delete-field="deleteField"
            />
          </template>
          <b-button
            class="mt-1"
            size="small"
            @click="addField"
          >
            Добавить доп поле
          </b-button>
        </div>
      </template>

      <div class="mt-3 mb-3 mt-3">
        Скидки по возрасту
      </div>
      <div>
        <div class="discount__items">
          <div
            v-for="(item, i) in distance.discounts"
            :key="'discount_' + i"
            class="discount__item"
          >
            <div
              class="discount__item-del"
              style="margin-bottom: 10px"
              @click="distance.discounts.splice(i, 1)"
            >
              <svg
                width="10"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"
                />
              </svg>
            </div>
            <b-form-input
              v-model.number="item.c_to"
              type="number"
              placeholder="От"
              style="margin-bottom: 10px"
            />
            <b-form-input
              v-model.number="item.c_from"
              type="number"
              placeholder="До"
              style="margin-bottom: 10px"
            />
            <div class="switch">
              <div style="margin-bottom: 10px">
                Скидка в %
              </div>
              <b-form-checkbox
                v-model="item.percent"
                class="custom-control-primary"
                name="check-reg"
                style="margin-bottom: 10px"
                switch
              />
            </div>
            <b-form-input
              v-model.number="item.value"
              style="margin-bottom: 10px"
              type="number"
              :placeholder=" item.percent ? '% скидки' : 'скидка(руб)'"
            />
          </div>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="submit"
          variant="primary"
          class="mr-1"
          @click="
            distance.discounts.push({
              c_to: null,
              c_from: null,
              value: null,
              percent: false,
            })
          "
        >
          Добавить скидку
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BCol, BFormCheckbox, BFormInput, BFormRadio, BModal, BRow, VBPopover,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import GroupTemplates from '@/views/starts/GroupTemplates'
import DistanceBlock from './DistanceBlock.vue'
import Group from './Group.vue'
import StageBlock from './StageBlock.vue'
import AdditionalField from './AdditionalField.vue'

export default {
  name: 'DistanceModalCreate',
  components: {
    StageBlock,
    Group,
    DistanceBlock,
    GroupTemplates,
    BRow,
    BCol,
    BFormInput,
    BFormCheckbox,
    BButton,
    BModal,
    BFormRadio,
    AdditionalField,
    vSelect,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: ['age_groups', 'select_kinds_sport'],
  data() {
    return {
      activeFormat: '',
      distance: {
        description: '',
        format: '',
        slots: 1,
        track_link: {},
        track_code: '',
        groups: [],
        discounts: [],
        additional_fields: [],
        stages: [],
      },
      groups: [],
      distanceStages: [],
      slotDisable: false,
      activeSelectSport: false,
      new_kind_sport: '',
      sport_formats: ['Лично', 'Эстафета'],
    }
  },
  methods: {
    deleteStage(data) {
      const indexStage = this.distance.stages.findIndex(stage => stage.uuid === data.stageUUID)
      this.distance.stages.splice(indexStage, 1)
    },
    addStage() {
      this.distance.stages.push(
        {
          uuid: uuidv4(),
          name: null,
          sex: null,
          additional_fields: [],
          kind_of_sport_id: null,
          stage_length: null,
        },
      )
    },
    addGroup() {
      if (this.distance.format === 'Лично') {
        this.distance.groups.push({
          name: null, age_from: null, age_to: null, age_reference_date: null,
        })
      } else if (this.distance.format === 'Эстафета') {
        this.distance.groups.push({
          name: null,
          age_from: null,
          age_to: null,
          age_reference_date: null,
          combination_dict: {},
        })
      }
    },
    addNewSport() {
      if (this.distance.name) {
        this.distance.uuid = uuidv4()
        if (this.distance.static_price) {
          this.distance.static_price = this.distance.static_price * 100
        }
        if (this.distance.additional_fields) {
          this.distance.additional_fields.map(field => {
            if (field.price) {
              field.price *= 100
            }
          })
        }
        if (this.distance.stages.length > 0) {
          this.transformStagesFields()
        }
        if (this.distance.groups.length > 0 && this.distance.format === 'Эстафета') {
          this.transformGroupsFields()
        }
        if (this.distance.date_prices) {
          this.distance.date_prices.map(discount => {
            discount.price *= 100
          })
        }
        if (this.distance.discounts) {
          this.distance.discounts.map(discount => {
            if (!discount.percent) {
              discount.value *= 100
            }
          })
        }
        if (this.distance.format === 'Эстафета' || this.distance.groups) {
          this.distance.groups.forEach(group => {
            if (group.combination_list) {
              group.combination_list.forEach(stage => {
                if (!stage.sex) {
                  stage.sex = 'Any'
                }
              })
            }
          })
        }
        this.select_kinds_sport.push(this.distance)
      }
      this.groups = []
      this.new_kind_sport = ''
      this.activeFormat = ''
      this.distanceStages = []
      this.distance = {
        description: '',
        slots: 1,
        format: '',
        track_link: {},
        track_code: '',
        groups: [],
        additional_fields: [],
        discounts: [],
      }
    },
    transformStagesFields() {
      this.distance.stages.forEach(stage => {
        if (stage.additional_fields.length > 0) {
          stage.additional_fields.forEach(field => field.price *= 100)
        }
      })
    },
    transformGroupsFields() {
      this.distance.groups.forEach(group => {
        if (Object.keys(group.combination_dict).length > 0) {
          group.combination_list = []
          for (const combinationDictKey in group.combination_dict) {
            group.combination_list.push({
              stageUUID: combinationDictKey,
              sex: group.combination_dict[combinationDictKey].length > 1 || !group.combination_dict[combinationDictKey] ? 'Any' : group.combination_dict[combinationDictKey][0],
            })
          }
        }
        delete group.combination_dict
      })
    },
    updateGroup(group, groupIndex) {
      this.groups[groupIndex] = group
    },
    deleteGroup(indexGroup) {
      this.distance.groups.splice(indexGroup, 1)
    },
    resetModal() {
      this.groups = []
      this.new_kind_sport = ''
      this.activeFormat = ''
      this.distanceStages = []
      this.distance = {
        description: '',
        slots: 1,
        track_link: {},
        track_code: '',
        groups: [],
        additional_fields: [],
        stages: [],
        format: '',
        discounts: [],
      }
    },
    addField() {
      const distance_uuid = uuidv4()
      this.distance.additional_fields.push({
        name: '',
        type: '',
        uuid: distance_uuid,
      })
    },
    updateField(field, fieldIndex) {
      this.distance.additional_fields[fieldIndex] = field
    },
    deleteField(fieldIndex) {
      this.distance.additional_fields.splice(fieldIndex, 1)
    },
    selectFormat(format) {
      this.distance.stages = []
      if (format === 'Лично') {
        this.distance.stages.push({
          uuid: uuidv4(), name: 'Лично', sex: '', additional_fields: [], kind_of_sport_id: null, stage_length: '',
        })
      } else {
        this.distance.stages.push({
          uuid: uuidv4(), name: '', sex: '', additional_fields: [], kind_of_sport_id: null, stage_length: '',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.add-fille {
  margin: 10px 0;
}
.distance {
  margin-top: 30px;
}
.title-format{
  margin: 15px 0!important;
}
.input__code{
  margin-top: 15px!important;
}
.con-example-prompt{
  input{
    margin-top: 20px!important;
  }
}
.distances__minus{
  margin-left: 5px;
  transition: .3s;
  &:hover{
    cursor: pointer;
    transform: scale(1.1);
  }
}
    .sport-block {
        border-bottom: 1px solid #e5e5e5;
    }

    .z-index {
        z-index: 52011;
    }

.price{
  &__title{
    margin-top: 20px;
  }
  &__items{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px 20px -15px;
  }
  &__item{
    padding: 20px;
    border: 1px solid #00000012;
    border-radius: 20px;
    position: relative;
    width: calc(50% - 30px);
    margin: 10px 15px 0 15px;

    &-del{
      position: absolute;
      right: 20px;
      top: 10px;
    }
  }

}

</style>
<style>
.flex{
  display: flex;

}
.justify-end{

  justify-content: end;
}
.inline-block{
  display: inline-block;
}
.age{
  display: flex;

}
.age input:last-child{
  margin-left: 30px;
}
.price__item .form-control{
  margin-top: 20px;
}
</style>
